import React from "react";

function getList() {
  const affList = [
    {
      name: "angular",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.756150&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fthe-complete-guide-to-angular-2%2F"
      >
        Angular - The Complete Guide (2023 Edition)
      </a>,
    },
    {
      name: "c",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1467808&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fc-programming-for-beginners-%2F"
      >
        C Programming For Beginners - Master the C Language
      </a>,
    },
    {
      name: "python",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.567828&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fcomplete-python-bootcamp%2F"
      >
        2023 Complete Python Bootcamp: From Zero to Hero in Python
      </a>,
    },
    {
      name: "react",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1362070&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Freact-the-complete-guide-incl-redux%2F"
      >
        React - The Complete Guide (incl Hooks, React Router, Redux)
      </a>,
    },
    {
      name: "vuejs",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.995016&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fvuejs-2-the-complete-guide%2F"
      >
        Vue - The Complete Guide (w/ Router, Vuex, Composition API)
      </a>,
    },
    {
      name: "nuxt",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1523224&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fnuxtjs-vuejs-on-steroids%2F"
      >
        Nuxt. js - Vue. js on Steroids
      </a>,
    },
    {
      name: "algorithms",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1409142&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fcoding-interview-bootcamp-algorithms-and-data-structure%2F"
      >
        The Coding Interview Bootcamp: Algorithms + Data Structures
      </a>,
    },
    {
      name: "datastructures",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1409142&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fcoding-interview-bootcamp-algorithms-and-data-structure%2F"
      >
        The Coding Interview Bootcamp: Algorithms + Data Structures
      </a>,
    },
    {
      name: "svelte",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.2360566&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fsveltejs-the-complete-guide%2F"
      >
        Svelte.js - The Complete Guide (incl. Sapper.js)
      </a>,
    },
    {
      name: "redux",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.2365628&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcomplete-react-developer-zero-to-mastery%2F"
      >
        Complete React Developer in 2023 (w/ Redux, Hooks, GraphQL)
      </a>,
    },
    {
      name: "typescript",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/deeplink?id=wGVEwkf7KWY&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Ftypescript-the-complete-developers-guide%2F"
      >
        Typescript: The Complete Developer's Guide
      </a>,
    },
    // {
    //     name: "javascript", refUrl: <a target='new' href="https://BeginnerJavaScript.com/friend/REACTGO">
    //         Learn Modern JavaScript from scratch with Wes Bos</a>
    // },
    {
      name: "css",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1561458&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fcss-the-complete-guide-incl-flexbox-grid-sass%2F"
      >
        CSS - The Complete Guide 2023 (incl. Flexbox, Grid & Sass)
      </a>,
    },
    {
      name: "gatsby",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.2176554&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fgatsby-tutorial-and-projects-course%2F"
      >
        Gatsby Tutorial and Projects Course
      </a>,
    },
    {
      name: "html",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.2153774&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fmodern-html-css-from-the-beginning%2F"
      >
        Modern HTML & CSS From The Beginning (Including Sass)
      </a>,
    },
    {
      name: "graphql",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1720558&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fgraphql-bootcamp%2F"
      >
        The Modern GraphQL Bootcamp (with Node. js and Apollo)
      </a>,
    },

    {
      name: "devtools",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1362070&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Freact-the-complete-guide-incl-redux%2F"
      >
        React - The Complete Guide (incl Hooks, React Router, Redux)
      </a>,
    },
    {
      name: "webdev",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.2508942&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fjavascript-the-complete-guide-2020-beginner-advanced%2F"
      >
        JavaScript - The Complete Guide 2023 (Beginner + Advanced)
      </a>,
    },
    {
      name: "javascript",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.2508942&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fjavascript-the-complete-guide-2020-beginner-advanced%2F"
      >
        JavaScript - The Complete Guide 2023 (Beginner + Advanced)
      </a>,
    },

    {
      name: "git",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.818990&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fgit-a-web-developer-job-mastering-the-modern-workflow%2F"
      >
        Git a Web Developer Job: Mastering the Modern Workflow
      </a>,
    },
    {
      name: "github",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.818990&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fgit-a-web-developer-job-mastering-the-modern-workflow%2F"
      >
        Git a Web Developer Job: Mastering the Modern Workflow
      </a>,
    },
    {
      name: "nodejs",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1879018&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fnodejs-the-complete-guide%2F"
      >
        NodeJS - The Complete Guide (incl. MVC, REST APIs, GraphQL)
      </a>,
    },
    {
      name: "npm",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1879018&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fnodejs-the-complete-guide%2F"
      >
        NodeJS - The Complete Guide (incl. MVC, REST APIs, GraphQL)
      </a>,
    },
    // {
    //     name: "react", refUrl: <a target='new' href="https://AdvancedReact.com/friend/REACTGO">
    //         Advanced React - Build an online store with React and GraphQL
    // </a>
    // }

    {
      name: "web",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1565838&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-web-development-bootcamp%2F"
      >
        The Complete 2023 Web Development Bootcamp
      </a>,
    },

    {
      name: "vscode",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.2508942&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fjavascript-the-complete-guide-2020-beginner-advanced%2F"
      >
        JavaScript - The Complete Guide 2023 (Beginner + Advanced)
      </a>,
    },

    {
      name: "d3.js",
      refUrl: <a
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1918908&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fbuild-data-uis-with-d3-firebase%2F"
      >
        Build Data Visualizations with D3. js & Firebase
      </a>,
    },
    {
      name: "deno",
      refUrl: <a
        target="new"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.3138058&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fdeno-the-complete-guide-zero-to-mastery%2F"
      >
        Deno: The Complete Guide Zero to Mastery
      </a>,
    },
    {
      name: "php",
      refUrl: <a
        target="new"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.405282&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fphp-for-complete-beginners-includes-msql-object-oriented%2F"
      >
        PHP for Beginners - Become a PHP Master - CMS Project
      </a>,
    },
    {
      name: "java",
      refUrl: <a
        target="new"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.533682&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fjava-the-complete-java-developer-course%2F"
      >
        Java Programming Masterclass for Software Developers
      </a>,
    },
    {
      name: "swift",
      refUrl: <a
        target="new"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1778502&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fios-13-app-development-bootcamp%2F"
      >
        iOS 13 & Swift 5 - The Complete iOS App Development Bootcamp
      </a>,
    },
    {
      name: "cpp",
      refUrl: <a
        target="new"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1576854&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fbeginning-c-plus-plus-programming%2F"
      >
        Beginning C++ Programming - From Beginner to Beyond
      </a>,
    },
    {
      name: "r",
      refUrl: <a
        target="new"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.765242&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fr-programming%2F"
      >
        R Programming A-Z: R For Data Science With Real Exercises!
      </a>,
    },
    {
      name: "go",
      refUrl: <a
        target="new"
        href="https://click.linksynergy.com/deeplink?id=wGVEwkf7KWY&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fgo-the-complete-developers-guide%2F"
      >
        Go: The Complete Developer's Guide (Golang)
      </a>,
    },

    {
      name: "ruby",
      refUrl: <a
        target="new"
        rel="nofollow noopener noreferrer"
        href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.739512&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fcomprehensive-ruby-programming-tutorial%2F"
      >
        Comprehensive Ruby Programming
      </a>,
    },
  ];

  return affList;
}

export default getList;

// { name: "graphql", refUrl: <a target='new' href="https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1720558&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fgraphql-bootcamp%2F">The Modern GraphQL Bootcamp (with Node. js and Apollo)</a> },
