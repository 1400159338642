import React, { Component } from 'react'
import './rec.css'
import Balance from 'react-icons/lib/io/arrow-graph-down-right'
import affList from '../../usage/aff-linklist';
// import { Link } from 'gatsby';

class Recommended extends Component {


    render() {
        const { tags } = this.props;
        const affLink = affList().filter((aff) => {
            return aff.name === tags[0]
        })
        if (affLink[0]) {
            return (
                <div className="recommended">
                    <fieldset>
                        <legend className="leg-des">
                            reactgo.com recommended course
                            <sub className="rec-icon"><Balance size={'36px'} /></sub>
                        </legend>
                        {affLink[0].refUrl}
                    </fieldset>
                    {/* <div className="info-link">
                        <Link to="/aff-disclosure" >About this Link</Link>
                    </div> */}
                </div>
            )
        } else {
            return <></>
        }
    }
}


export default Recommended;