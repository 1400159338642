import React from 'react'
import { Link } from 'gatsby'
import './footer.css'
import './header.css'


function Footer({ siteTitle }) {
  return (

    <footer className={"footer-g"}>
      <hr />

      <section className="footer-start">

        <nav className="footer-links">
          <Link to="/">
            {siteTitle}
          </Link>
          <a href="/advertise">Advertise</a>
          <a href="https://twitter.com/saigowthamr" target="_blank"> @saigowthamr</a>
          <a href="/privacy-policy"
          >Privacy policy</a>
          <a href="/aff-disclosure"
          >Affiliate Disclosure</a>
          {/* <a href="https://codeexamples.dev">Code Examples</a> */}
        </nav>
        <div className="center">
          <form
            action="https://tinyletter.com/reactgo"
            method="post"
            target="popupwindow"
            onSubmit={() =>
              window.open(
                'https://tinyletter.com/reactgo',
                'popupwindow'
              )
            }
            className={"embeddable-buttondown-form form"}
          >
            <h4 className="animate-text">Get my latest tutorials</h4>

            <label htmlFor="bd-email" className="label">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="bd-email"
              className="email"
              placeholder="user@email.com"
              required
            />
            <input type="hidden" value="1" name="embed" />
            <input type="submit" className="subs" value="Send me" />
          </form>
        </div>
      </section>
      <center> © {new Date().getFullYear()} Reactgo </center>
    </footer>



  )
}

export default Footer

{/* <a href=" https://www.privacypolicygenerator.info/live.php?token=pescwVOAGYlR9kfya5oMr5Qc7YQyFkH3" target="_blank"
            style={{ marginLeft: '1rem' }}
          >Privacy</a> */}