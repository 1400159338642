import React, { Component } from 'react';
import './carbon.css';

class CarbonMain extends Component {

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            this.loadAd();
        }
    }

    loadAd = () => {
        const script = document.createElement("script");
        script.src = "//cdn.carbonads.com/carbon.js?serve=CE7DV2JU&placement=reactgocom";
        script.id = "_carbonads_js";
        script.defer = true;
        this.carbon.appendChild(script);
    }

    render() {
        return (
            <div className="normal"
                ref={(el) => this.carbon = el}></div>
        )
    }
}

export default CarbonMain;


