import React from "react";
import Header from '../components/header';
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import '../components/list.css';
import '../components/mainui/main.css';
import '../components/blog.css';
// Components
import MetaPost from "../components/MetaPost";
import Recommended from '../components/recommended';
import Footer from "../components/footer";
import CarbonMain from "../components/carbonads/main";



const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const noTuts = ["courses", "books", "laptops"]
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"
    } tagged with "${tag}"`;
  const finalSign = noTuts.includes(tag) ? "posts" : "tutorials"
  const tagHead = `${tag.charAt(0).toUpperCase() + tag.slice(1)} tutorials & Examples`

  let blogTag = tag === 'blogging' ? data.site.siteMetadata.blogging : data.site.siteMetadata.description
  return (
    <Layout>
      <Header siteTitle="Reactgo" />
      <MetaPost title={tagHead}
        description={blogTag}
        url={data.site.siteMetadata.siteUrl}
        thumbnail={'https://reactgo.com/img/download.png'}
        stop
      />
      <div className="post-list auto bglight ">
        <div className="padding-top3">
          <div className="ui-wrapper">
            <h1 className=" slim">Available {finalSign} in <span style={{ textDecoration: "overline" }}>{tag}</span></h1>
            <div className="content-wrapper">
              <main className="content-box">
                <div className="post-setup">
                  <Recommended tags={[tag]} />
                  {edges.map(({ node }, i) => (
                    <Link to={node.fields.slug} key={i} className="killer">
                      <div>
                        <h2>{node.frontmatter.title}</h2>
                        <time className="date">{node.frontmatter.date}</time>
                        <p className="excerpt">{node.excerpt}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </main>
              <aside className="bar-box" style={{ paddingTop: "1.8rem" }} >
                {/* <Book tags={[tag]} /> */}
                <CarbonMain />
              </aside>
            </div>
          </div>
        </div>
      </div>
      <Footer siteTitle="Home" />
    </Layout>
  )
}

export default Tags

export const tagsQuery = graphql`
  query Tags($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
            excerpt(pruneLength: 70)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM, Do")
          }
        }
      }
    }
     site {
      siteMetadata {
        description
        siteUrl
        blogging
      }
    }
  }
`